<template>
  <div class="ion-no-padding item">
    <p class="label">{{ label }}</p>
    <div class="ion-margin-top text-bold">{{ val }}</div>
  </div>
</template>

<script>
  export default {
    props: ['label', 'value', 'date'],
    computed: {
      val() {
        if (this.date && this.value) {
          return new Date(this.value).toLocaleDateString('es-CL')
        }

        if (this.date && !this.value) {
          return 'Unofficial'
        }

        return this.value ? this.value : 'Unknown'
      }

    }

  }
</script>
<style lang="sass" scoped>
.label
  font-size: 14px
div
  display: block
p
  display: block
  margin-top: 10px
  margin-bottom: 5px
.text-bold
  font-weight: bold
  font-size: 18px
  padding-bottom: 10px
  margin-top: 10px

.item
  border-bottom: 1px solid rgb(193,191,197)
</style>
