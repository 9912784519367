<template>
  <ion-page>
    <div v-if="isLoading" class="load-wrap ion-text-center">
      <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      <p>Loading...</p>
    </div>
    <ion-header>
      <ion-toolbar v-if="!isEmpty(country)">
        <ion-buttons slot="start" >
          <ion-button icon-only @click="$router.go(-1)">
            <ion-icon :icon="arrowBack"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title class="ion-no-padding">
          <div class="pad d-flex ion-align-items-center ion-justify-content-center">
            {{ country.title }} ({{ country.code }})
            <img class="flag-icon ion-margin-start" :src="country.flag?.path" :alt="country.title">
          </div>
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content ref="myContent" v-if="!isEmpty(country)" class="ion-no-padding ion-padding-top" fullscreen>
      <div class="d-flex ion-align-items-center ion-justify-content-center">
        <span v-if="isLoggedIn">
          {{ country.pins_owned_count}} out of {{ country.pins_total_count }}
        </span>
        <span v-else>
          {{ country.pins_total_count == 1 ? `${country.pins_total_count} pin` : `${country.pins_total_count} pins` }}
        </span>
      </div>
      <div class="margin-top">
        <div class="ion-text-center image">
          <img :src="country.map?.path" :alt="country.map?.path">
        </div>
        <div class="a-country-detail__creator ion-margin-vertical ion-text-center">{{ country.creator }}</div>
        <div v-if="country.note" class="ion-margin-vertical ion-text-center">{{ country.note }}</div>

        <ion-grid class="ion-padding-horizontal">
          <ion-row class="ion-padding-bottom ion-justify-content-center">
            <ion-col size="6" size-md="4" class="ion-padding-end ion-justify-content-between ion-align-items-center">
              <z-summary :label="'Created'" :value="country.created" date="true"></z-summary>
            </ion-col>
            <ion-col size="6" size-md="4" class="ion-padding-start ion-justify-content-between ion-align-items-center">
              <z-summary :label="'Recognition date'" :value="country.recognized" date="true"></z-summary>
            </ion-col>
          </ion-row>
        </ion-grid>
        <z-pin-list v-if="country.id" module="countries" :pins="pins" :countryId="this.country.id" />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { defineComponent } from 'vue'
  import { arrowBack } from 'ionicons/icons'
  import {mapActions, mapGetters} from 'vuex'
  import wAxios from '@/plugins/w/axios'
  import ZSummary from '@/plugins/app/_components/z-summary.vue'

  const api = {
    country: (id) => wAxios.get_auth(`v1/countries/${id}`)
  }

  export default defineComponent({

    async created() {
      await this._fetchCountry()
    },

    async ionViewDidEnter() {
      if (this.needRefresh) {
        await this._fetchCountry()
        this.changeNeedRefresh(false)
        this.$refs.myContent?.$el?.scrollToPoint(0, 0)
      }
    },

    data() {
      return {
        arrowBack,
        country: {}
      }
    },

    computed: {
      ...mapGetters('countries', ['pins', 'isLoading', 'needRefresh']),
      ...mapGetters('wAuth', ['isLoggedIn'])
    },

    components: {
      ZSummary
    },

    methods: {
      ...mapActions('countries', ['changeNeedRefresh']),

      async _fetchCountry(){
        try {
          this.country = await api.country(this.$route.params.id)
          await this.$store.dispatch(`countries/fetchByPage`, { country: this.country.id, page:1})
        } catch (err) {
          console.error(err)
        }
      },
       isEmpty(obj) {
        return Object.keys(obj).length === 0
      },
    }
  })
</script>

<style lang="sass">
.image
  max-width: 300px
  width: 60%
  margin: 0 auto
.margin-top
  margin-top: 50px
.a-country-detail
  &__creator
    font-size: 16px
    line-height: 20px
    text-transform: uppercase
.flag-icon
  height: 15px
.pad
  padding-left: 50px
  padding-right: 50px
.load-wrap
  padding-top: 40vh
  width: 100%
  height: 100vh
  position: fixed
  background: rgba(255,255,255, 0.8)
  z-index: 10000
</style>
